// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-arrow-js": () => import("./../../../src/pages/arrow.js" /* webpackChunkName: "component---src-pages-arrow-js" */),
  "component---src-pages-footer-js": () => import("./../../../src/pages/footer.js" /* webpackChunkName: "component---src-pages-footer-js" */),
  "component---src-pages-header-js": () => import("./../../../src/pages/Header.js" /* webpackChunkName: "component---src-pages-header-js" */),
  "component---src-pages-hover-js": () => import("./../../../src/pages/Hover.js" /* webpackChunkName: "component---src-pages-hover-js" */),
  "component---src-pages-hover-popup-js": () => import("./../../../src/pages/hoverPopup.js" /* webpackChunkName: "component---src-pages-hover-popup-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-timeline-js": () => import("./../../../src/pages/Timeline.js" /* webpackChunkName: "component---src-pages-timeline-js" */),
  "component---src-pages-typing-text-js": () => import("./../../../src/pages/typingText.js" /* webpackChunkName: "component---src-pages-typing-text-js" */),
  "component---src-pages-updates-js": () => import("./../../../src/pages/updates.js" /* webpackChunkName: "component---src-pages-updates-js" */)
}

